import React, { useState } from 'react'
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import { find, isNil, last, nth, unionBy, uniq, } from 'lodash'
import { set } from 'lodash/fp'
import { format, isPast } from 'date-fns'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import {
  ExternalLink,
  ImageSlider,
  Layout,
  Modal,
  TwitterMeta
} from "../../components"
import { useSiteMetadata } from "../../utils"
import link from '../../assets/images/icons/link.svg'
import image from '../../assets/images/icons/image.svg'

function DemoDay({ data, location, pageContext, }) {
  const { additionalData } = pageContext
  const { siteURL, title: siteTitle } = useSiteMetadata()
  const myURL = `${siteURL}${location.pathname}`
  const demoDay = data.wpDemoDay
  const { id, slug } = demoDay
  const {
    description,
    detailDescription,
    detailTitle,
    displayNumber,
    launchDate,
    media,
    number,
    videoLink,
  } = demoDay.fields
  const title = `Demo Day ${displayNumber}`
  const missions = data.allWpMission.nodes
  const demoDayMissions = missions.filter(({ fields: { demoDay: { id: missionId } } }) => missionId === id)
  const allDemoDays = data.allWpDemoDay.nodes.map((node) => {
    return set('fields.launchDate', new Date(node.fields.launchDate), node)
  })

  return (
    <Layout>
      <Helmet
        title={`${title} - ${siteTitle}`}
        defer={false}
        bodyAttributes={{ class: slug }}
      />
      <TwitterMeta
        title={title}
        image="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/lpl-logo.svg?1593194907"
        url={myURL}
      />
      <DemoDayHeader
        description={description}
        displayNumber={displayNumber}
        launchDate={launchDate}
      />
      <DemoDayDescription
        detailDescription={detailDescription}
        detailTitle={detailTitle}
      />
      <DemoDayStatistics demoDayMissions={demoDayMissions} additionalData={additionalData} />
      {videoLink && (
        <iframe
          title={`Demo Day ${displayNumber} Video`}
          width="560"
          height="315"
          src={videoLink}
          frameBorder="0"
          allowFullScreen
        />
      )}
      <DemoDayMissions demoDayMissions={demoDayMissions} additionalData={additionalData} />
      {media && (
        <ImageSlider images={media} title="A Glimpse into the Day" />
      )}
      <DemoDayPaginators allDemoDays={allDemoDays} thisDemoDayNumber={number} />
    </Layout>
  )
}

export default DemoDay

function DemoDayHeader({ description, displayNumber, launchDate }) {
  const launchDateAsDate = new Date(launchDate)

  return (
    <header className="page-header">
      <span className="color-tint"></span>
      <div className="text-container">
        <p className="date">{format(launchDateAsDate, 'MMMM d, yyyy')}</p>
        <h1>Demo Day {displayNumber}</h1>
        <p>{description}</p>
      </div>
    </header>
  )
}

function DemoDayDescription({ detailDescription, detailTitle }) {
  return (
    <section className="demo-day-description">
      {detailTitle && <h2>{detailTitle}</h2>}
      {detailDescription && <p>{detailDescription}</p>}
    </section>
  )
}

function DemoDayStatistics({ demoDayMissions, additionalData, }) {
  const contributorCount = () => {
    const missionIds = demoDayMissions.map(({ databaseId, }) => databaseId)
    const { missions } = additionalData
    let contributorIds = []
    missions.forEach((mission) => {
      if (missionIds.some((id) => id === mission.id)){
        const {
          acf: {
            commander,
            team_members: teamMembers,
          }
        } = mission
        contributorIds.push(commander.ID)
        contributorIds = contributorIds.concat((teamMembers || []).map(({ ID }) => ID))
      }
    })
    return uniq(contributorIds).length
  }
  return (
     <section className="grid-block-container">
      <ul>
        <li>
          <h2>{demoDayMissions.length}</h2>
          <p>Number of Missions</p>
        </li>
        <li>
          <h2>{contributorCount()}</h2>
          <p>Contributors</p>
        </li>
      </ul>
    </section>
  )
}

function DemoDayMissions({ demoDayMissions, additionalData, }) {
  const { missions } = additionalData
  const additionalMissionData = (mission) => (find(missions, (m) => (m.id === mission.databaseId)))

  return (
    <section className="mission-block-container">
      <h2>Missions</h2>
      {demoDayMissions.map((mission) => (
        <MissionSummary key={mission.id} mission={mission} additionalMissionData={additionalMissionData(mission)} />
      ))}
    </section>
  )
}

function MissionSummary({ mission, additionalMissionData, }) {
  const [displayImageModal, setDisplayImageModal] = useState(false)
  const slug = mission.slug
  const {
    blogPostUrl,
    description,
    primaryImage,
    teaser,
    title,
  } = mission.fields

  const {
    acf: {
      commander,
      team_members: teamMembers,
    }
  } = additionalMissionData || {}

  return (
    <div className="mission-block">
      <div className="text-block-wrapper">
        <span className="line"></span>
        <div className="text-block">
          <h3>{title}</h3>
          <MissionTeam commander={commander.data} teamMembers={teamMembers || []} />
          <p>{teaser}</p>
        </div>
      </div>
      <div className="link-block">
        {blogPostUrl && (
          <ExternalLink
            className="gradient-button"
            aria-label="Blog post link"
            href={blogPostUrl}
          >
            <img src={link} alt=""/>
          </ExternalLink>
        )}
        {primaryImage && (
          <>
            <button
              className="gradient-button"
              aria-label={`View Mission ${title} Image`}
              onClick={() => setDisplayImageModal(true)}
            >
              <img src={image} alt=""/>
            </button>
            <Modal isOpen={displayImageModal} onClose={() => setDisplayImageModal(false)}>
              <img alt={primaryImage.altText} src={primaryImage.sourceUrl} />
            </Modal>
          </>
        )}
        {/* TDB: Currently, no support for a mission video */}
        {description && (
          <a
            className="gradient-button text"
            aria-label="Learn More"
            href={`/missions/${slug}`}
          >
            <span>Learn More</span>
          </a>
        )}
      </div>
    </div>
  )
}

function MissionTeam({ commander, teamMembers }) {
  const teamMemberNames = teamMembers ? teamMembers.map(({ display_name }) => display_name) : []
  const allTeamMemberNames = unionBy([commander?.display_name], teamMemberNames).join(', ')

  return (
    allTeamMemberNames.length > 0 ? <p className="authors">By: {allTeamMemberNames}</p> : null
  )
}

function DemoDayPaginators({ allDemoDays, thisDemoDayNumber }) {
  // Note that DemoDays are sorted by their "number". The zeroth demo day, which
  // has a null "number" value, appears at the end of the sort order. Therefore,
  // the "first" Demo Day is the last entry in the allDemoDays array and the
  // most recent Demo Day is the second-to-last Demo Day in the allDemoDays array.

  // Filter out the next (not yet presented) Demo Day for pagination
  const pastDemoDays = allDemoDays.filter(({ fields: { launchDate } }) => isPast(launchDate))

  // Explicitly set the first Demo Day "number" to 0 so that integer arithmentic can be used
  const firstDemoDay = last(pastDemoDays)
  firstDemoDay.fields.number = 0

  const nextDemoDay = getNextDemoDay(thisDemoDayNumber, pastDemoDays)
  const previousDemoDay = getPreviousDemoDay(thisDemoDayNumber, pastDemoDays)

  return (
    <section className="paginators-block-container">
      <DemoDayPaginator demoDay={previousDemoDay} direction="previous" />
      <DemoDayPaginator demoDay={nextDemoDay} direction="next" />
    </section>
  )
}

function DemoDayPaginator({ demoDay, direction }) {
  if (isNil(demoDay)) return null

  const {
    displayNumber,
    launchDate,
  } = demoDay.fields
  const LinkGraphic = () => (
    <a
      className="button-primary"
      aria-label={`Link to Demo Day ${displayNumber}`}
      href={`/demo_days/${demoDay.slug}`}
    >
      {direction === 'previous' ? <MdChevronLeft /> : <MdChevronRight />}
    </a>
  )

  return (
    <div className={`paginator-block ${direction}`}>
      {direction === 'previous' && <LinkGraphic />}
      <div className="text-block">
        <p>{format(launchDate, 'MMM yyyy')}</p>
        <h3>Demo Day {displayNumber}</h3>
      </div>
      {direction === 'next' && <LinkGraphic />}
    </div>
  )
}

function getNextDemoDay(thisDemoDayNumber, allDemoDays) {
  const lastDemoDay = nth(allDemoDays, -2)
  const nextDemoDayNumber = thisDemoDayNumber + 1

  return nextDemoDayNumber > lastDemoDay.fields.number
    ? null
    : find(allDemoDays, ['fields.number', nextDemoDayNumber])
}

function getPreviousDemoDay(thisDemoDayNumber, allDemoDays) {
  const previousDemoDayNumber = thisDemoDayNumber - 1

  return previousDemoDayNumber < 0
    ? null
    : find(allDemoDays, ['fields.number', previousDemoDayNumber])
}

export const query = graphql`
query DemoDayPage($id: String! = "") {
  wpDemoDay(id: {eq: $id}) {
    id
    fields: demo_day_fields {
      description
      detailDescription
      detailTitle
      displayNumber
      launchDate
      number
      videoLink
      media {
        altText
        sourceUrl
      }
    }
    slug
  }
  allWpMission {
    nodes {
      id
      databaseId
      slug
      fields: mission_fields {
        demoDay {
          ... on WpDemoDay {
            id
          }
        }
        description
        teaser
        title
        teamMembers {
          name
        }
        commander {
          name
        }
        blogPostUrl
        primaryImage {
          altText
          sourceUrl
        }
      }
    }
  }
  allWpDemoDay(sort: {fields: demo_day_fields___number}) {
    nodes {
      fields: demo_day_fields {
        displayNumber
        launchDate
        number
      }
      slug
    }
  }
}
`
